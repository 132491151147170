<template>
  <div>
    <div class="container my-5">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-6">
          <div class="mb-2">
            <label for="login"
              ><b>{{ $t("login.email") }}</b></label
            >
            <input
              type="text"
              :placeholder="$t('login.loginPh')"
              v-model="userLogin"
              required
            />
          </div>

          <div class="mb-2">
            <label for="psw"
              ><b>{{ $t("login.pass") }}</b></label
            >
            <input
              type="password"
              :placeholder="$t('login.passPh')"
              v-model="userPass"
              required
            />
          </div>

          <vue-recaptcha class="mt-2"
            ref="recaptcha"
            @verify="onVerify"
            @expired="onExpired"
            :sitekey="$recaptchaKey"
            :loadRecaptchaScript="true"
          >
          </vue-recaptcha>

          <b-button class="mt-3" variant="primary" @click="submitLogin" :disabled=canSubmit>
            {{ $t("login.login") }}
          </b-button>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Login",
  components: { VueRecaptcha },
  data() {
    return {
      userLogin: "",
      userPass: "",
      token: "",
      canSubmit: true
    };
  },
  methods: {
    submitLogin() {
      let sendData = {
        login: this.userLogin,
        password: this.userPass,
        token: this.token,
      };
      console.log("submitLogin:", sendData)
      if (this.token !== "") {
        this.$store
          .dispatch("sendNonAuthenticatedPost", {
            endpoint: "/user/login",
            sendData: sendData,
          })
          .then((response) => {
            this.loading = false;
            this.errored = false;
            this.$store.commit("setAuthUser", response.data);
            this.$emit("loginComplete");
            this.$router.push("/mazdaapp");
          })
          .catch((error) => {
            console.log("Error", error);
            this.loading = false;
            this.errored = true;
          });
      }
    },
    onVerify: function (response) {
      this.token = response;
      if (this.$devmode) console.log('Verify: ' + response);
      this.canSubmit = false;
    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
      console.log("Expired");
      this.canSubmit = true;
    },
  },
};
</script>
